
$ = require("jquery"),
    require('jquery-ui-bundle');

window.jQuery = $;
window.$ = $;

require('babel-polyfill');
import axios from 'axios';
import 'bootstrap';

//global vars
var menuSubPopup = false;


window.comment_form_clear = function()
{
    $('#comment-title').val('');
    $('#comment-message').val('');
    $('#comment-form input[name=idp]').val('0');
    $('#files').html('');
}

window.show_error = function(err)
{
    $('#cabinet-error-message .alert-description').text(err);
    if (err == '') {
        $('#cabinet-error-message').hide();
    } else {
        $('#cabinet-error-message').show();
        $('html, body').animate({
           scrollTop: $("#cabinet-error-message").offset().top
       }, 1000);
    }
};

window.remove_tmp_upload_file = function(file_id, create_token)
{
    $.ajax({
        type: "POST",
        url: BASE_URL + '/ajax/json/remove_tmp_upload_file/',
        dataType: 'json',
        data: {
            file_id:      file_id,
            create_token: create_token
        },
        success: function(response) {
            if (!response.success) {
                show_error(response.error);
            } else {
                $('#tmp_upload_file_' + file_id).remove();
            }
        },
    });
};

$(document).ready(function() {

    $('#satin')
        .on('click', function(){
          $('#satin').fadeOut(100);
          $('#mainmenu-popup').fadeOut(200);
        });
    $('#search-close')
        .on('click', function(){
            $('#search-popup').fadeOut(200);
        });

    $('#mainmenu-btn')
      .on('click',
          function(){
            $("#satin").fadeIn(100, function(){
              $("#mainmenu-popup").fadeIn(200);
            });
          }
        );
    $('#mainmenu-popup')
      .on('mouseleave',
        function(){
          if(menuSubPopup) { menuSubPopup = false; return; }
          $("#mainmenu-popup").fadeOut(200, function(){
            $("#satin").fadeOut(100);
          });
        }
      );
    $('#mainmenu-btn-popup, #mainmenu-popup ul li a')
      .on('click',
        function(){
          $("#mainmenu-popup").fadeOut(200, function(){
            $("#satin").fadeOut(100);
          });
        }
      );

    $('#search-btn')
        .on('click', function() {
          $("#search-popup").fadeIn(200, function(){
            $("#search-input").focus();
          });
        });

    //language selector:
    $("#languageSelector, #languageSelectorMobile").on('click', function(event) {
        event.preventDefault();
        var rect = document.getElementById(this.id).getBoundingClientRect();
        
        menuSubPopup = true;

        $('#languageSelectorPopup')
          .css('top', rect.top - 5)
          .css('left', rect.left - 10)
          .fadeIn(200)
          .focus();
    });

    $("#languageSelectorPopup").on('mouseleave blur', function(){
        $(this).fadeOut(200);
    });

    $(document).on("click", ".language-selector a", function() {
        var lang = $(this).data('lang');

        if (location.href.indexOf(BASE_URL + '/' + LANG) === -1) {
            location.href = BASE_URL + '/' + lang + '/';
        } else {
            location.href = BASE_URL + '/' + lang + '/';
        }
    });

    // comments
    if ($('#comment-form-box').length) {
        window.comment_form_html = $('#comment-form-box').html();
    }

    $(document).on("click", ".comment-body .reply-btn", function() {

        var comment_id = $(this).data('comment_id');

        $('#comment-form-box').remove();
        $('#comment-body-' + comment_id).append('<div id="comment-form-box" class="col-lg-12">' + window.comment_form_html + '</div>');
        $('#comment-form input[name=idp]').val(comment_id);
        $('.comment-form').hide(0);
        $('#comment-reply-cancel').show(0);
    });

    $(document).on("click", "#comment-reply-cancel", function() {
        $('#comment-form-box').remove();
        $('.comment-form').show(0).append('<div id="comment-form-box" class="col-lg-12">' + window.comment_form_html + '</div>');
    });
        
    $(document).on("click", "#comment-submit", function() {

        $.ajax({
            type: "POST",
            url: BASE_URL + '/ajax/json/comments/add/',
            dataType: 'json',
            data: $('#comment-form').serialize(),
            success: function(response) {

                var idp = $('#comment-form input[name=idp]').val();

                if (!response.success) {
                    show_error(response.error);
                } else {
                    comment_form_clear();

                    if (idp != '0') {
                        $('#comment-form-box').remove();
                        $('.comment-form').show(0).append('<div id="comment-form-box" class="col-lg-12">' + comment_form_html + '</div>');
                    }

                    if (idp != '0') {
                        if ($('#comments_all_children_of_' + idp).length == 0) {
                            $('#comment_' + idp).append('<div class="comments-list comments-all-children" id="comments_all_children_of_' + idp + '"></div>');
                        }
                        $('#comments_all_children_of_' + idp).append(response.comment_html);

                    } else {
                        $('.comments-box > .all-comments').append(response.comment_html);
                    }
                }
            },
        });
    });

    $("#show_hide_password a").on('click', function(event) {
        event.preventDefault();
        if ($('#show_hide_password input').attr("type") == "text"){
            $('#show_hide_password input').attr('type', 'password');
            $('#show_hide_password i').addClass( "fa-eye-slash" );
            $('#show_hide_password i').removeClass( "fa-eye" );
        } else if ($('#show_hide_password input').attr("type") == "password") {
            $('#show_hide_password input').attr('type', 'text');
            $('#show_hide_password i').removeClass( "fa-eye-slash" );
            $('#show_hide_password i').addClass( "fa-eye" );
        }
    });
});